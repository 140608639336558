<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>CTR</title>
    <path
      d="M11.71 17.99C8.53 17.84 6 15.22 6 12C6 8.69 8.69 6 12 6C15.22 6 17.84 8.53 17.99 11.71L15.89 11.08C15.48 9.31 13.89 8 12 8C9.79 8 8 9.79 8 12C8 13.89 9.31 15.48 11.08 15.89L11.71 17.99ZM22 12C22 12.3 21.99 12.6 21.96 12.9L19.99 12.31C20 12.21 20 12.1 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C12.1 20 12.21 20 12.31 19.99L12.9 21.96C12.6 21.99 12.3 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM18.23 16.26L22 15L12 12L15 22L16.26 18.23L20.53 22.5L22.51 20.52L18.23 16.26Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-ctr',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
